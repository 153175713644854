* {
  outline: none;
  box-sizing: border-box;
  hyphens: none !important;
}

body,
html {
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  color: $text-color;
  background-color: $background-color;
  @include media('tablet-wide', max) {
    font-size: calc(#{$base-font-size}* 0.75 + 0.4vw);
  }
  @media print {
    font-size: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
dl,
ol,
ul,
dd,
dt,
li {
  margin: 0;
  padding: 0;
}

@each $element, $size in $default-font-size {
  #{$element} {
    font-size: $size;
  }
}

@each $element, $line-height in $default-line-height {
  #{$element} {
    line-height: $line-height;
  }
}

@each $element, $margin-bottom in $default-margin-bottom {
  #{$element} {
    margin-bottom: $margin-bottom;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  color: $heading-color;
}

p,
blockquote {
  hyphens: auto;
}

a {
  //color: $link-color;
  text-decoration: none;
  transition: all 350ms ease-in-out;
  &:hover,
  &:focus,
  &:active {
    //color: darken($link-color, 10%);
    text-decoration: none;
  }
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

img {
  max-width: 100%;
}

$fontpath: "/files/fonts" !default;

@import "../iconfonts/cplogoiconfont";
@import "../iconfonts/cpiconfont";
@import "../iconfonts/cptoolboxiconfont";
@import "../iconfonts/cpzoneiconfont";
@import "../iconfonts/cpleveliconfont";
@import "../iconfonts/apprenticeshipiconfont";


@font-face {
    font-family: 'Neue Machina Regular';
    src: url('#{$fontpath}/neuemachina-regular.woff2') format('woff2'),
         url('#{$fontpath}/neuemachina-regular.woff') format('woff'),
         url('#{$fontpath}/neuemachina-regular.ttf') format('truetype'),
         url('#{$fontpath}/neuemachina-regular.svg#neue_machinaregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Machina Ultrabold';
    src: url('#{$fontpath}/neuemachina-ultrabold.woff2') format('woff2'),
         url('#{$fontpath}/neuemachina-ultrabold.woff') format('woff'),
         url('#{$fontpath}/neuemachina-ultrabold.ttf') format('truetype'),
         url('#{$fontpath}/neuemachina-ultrabold.svg#neue_machinaultrabold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
$neueregular: 'Neue Machina Regular';
$neuebold: 'Neue Machina Ultrabold';

@font-face {font-family: "Aspira W01 Medium";
  src: url("#{$fontpath}/aspira-medium.eot"); /* IE9*/
  src: url("#{$fontpath}/aspira-medium.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("#{$fontpath}/aspira-medium.woff2") format("woff2"), /* chrome、firefox */
  url("#{$fontpath}/aspira-medium.woff") format("woff"), /* chrome、firefox */
  url("#{$fontpath}/aspira-medium.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("#{$fontpath}/aspira-medium.svg#Aspira W01 Medium") format("svg"); /* iOS 4.1- */
}

@font-face {font-family: "Aspira W01 Black";
    src: url("#{$fontpath}/aspira-black.eot"); /* IE9*/
    src: url("#{$fontpath}/aspira-black.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("#{$fontpath}/aspira-black.woff2") format("woff2"), /* chrome、firefox */
    url("#{$fontpath}/aspira-black.woff") format("woff"), /* chrome、firefox */
    url("#{$fontpath}/aspira-black.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("#{$fontpath}/aspira-black.svg#Aspira W01 Black") format("svg"); /* iOS 4.1- */
  }

  $aspira-medium:  "Aspira W01 Medium", sans-serif;
  $aspira-black: "Aspira W01 Black", sans-serif;